<template>
  <div class="blog-wrapper">
    <!-- content -->
    <prof-item
      v-for="(prof, i) in profList"
      :key="i"
      :data="prof"
    />
    <!--/ content -->
  </div>
</template>

<script>
  import {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import ProfItem from './ProfItem.vue';
  import { kFormatter } from '@core/utils/filter';

  export default {
    components: {
      ProfItem,
      BFormInput,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BLink,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BImg,
      BBadge,
      BCardText,
      BDropdown,
      BForm,
      BDropdownItem,
      BFormTextarea,
      BFormCheckbox,
      BButton,
      // ContentWithSidebar,
    },
    directives: {
      Ripple,
    },
    data() {
      // content-header-title
      // console.log('bid: ', router.currentRoute.params.id);
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        profList: [
          {
            name: '정효지',
            depart: '보건학과 보건학전공',
            position: '교수',
            major: '보건영양학연구실',
            office: '220동 705호',
            email: 'hjjoung@snu.ac.kr',
            home: 'https://sites.google.com/view/snuphn',
            tel: '02-880-2716',
            image: require('@/assets/images/profile/3/hjjoung.jpg'),
          },
          {
            name: '고광표',
            depart: '환경보건확과',
            position: '교수',
            major: '환경보건미생물연구실',
            office: '220동 752호',
            email: 'gko@snu.ac.kr',
            home: 'http://ehml.snu.ac.kr',
            tel: '02-880-2731',
            image: require('@/assets/images/profile/3/gko.png'),
          },
          {
            name: '김석호',
            depart: '사회과학대학 사회학과',
            position: '부교수',
            major: '정치사회학, 시민사회, 이주와 사회통합, 사회조사방법론',
            office: '',
            email: '',
            home: '',
            tel: '',
            image: require('@/assets/images/profile/3/ksh.png'),
          },
          {
            name: '송욱',
            depart: '사범대학 체육교육과',
            position: '교수',
            major: '운동생리학, 운동생화학 영양학, 테니스',
            office: '',
            email: '',
            home: '',
            tel: '',
            image: require('@/assets/images/profile/3/song.png'),
          },
          {
            name: '원성호',
            depart: '보건학과 보건학 전공',
            position: '교수',
            major: '보건통계학연구실(Ⅱ)',
            office: '220동 707호',
            email: 'won1@snu.ac.kr',
            home: 'http://healthstat.snu.ac.kr/swon/',
            tel: '02-880-2714',
            image: require('@/assets/images/profile/3/won1.png'),
          },
          {
            name: '이승묵',
            depart: '환경보건확과',
            position: '교수',
            major: '대기환경 및 기후변화 연구실',
            office: '220동 750호',
            email: 'yiseung@snu.ac.kr',
            home: 'http://www.snuapcc.com',
            tel: '02-880-2736',
            image: require('@/assets/images/profile/3/yiseung.png'),
          },
          {
            name: '장덕진',
            depart: '사회과학대학 사회학과',
            position: '교수',
            major: '경제/조직사회학, 네트워크분석',
            office: '',
            email: '',
            home: '',
            tel: '',
            image: require('@/assets/images/profile/3/default.png'),
          },
          {
            name: '조경덕',
            depart: '환경보건확과',
            position: '교수',
            major: '환경화학모니터링연구실',
            office: '220동 730호',
            email: 'zohdk@snu.ac.kr',
            home: 'http://ehml.snu.ac.kr',
            tel: '02-880-2737',
            image: require('@/assets/images/profile/3/zohdk.png'),
          },
          {
            name: '조동성',
            depart: '인천대학교/서울대학교 경영대학',
            position: '총장/명예교수',
            major: '전략 및 국제경영',
            office: '',
            email: '',
            home: '',
            tel: '',
            image: require('@/assets/images/profile/3/default.png'),
          },
          {
            name: '조영태',
            depart: '보건학과 보건학 전공',
            position: '교수',
            major: '인구학연구실',
            office: '220동 732호',
            email: 'youngtae@snu.ac.kr',
            home: '',
            tel: '02-880-2820',
            image: require('@/assets/images/profile/3/youngtae.png'),
          },
          {
            name: '지근억',
            depart: '생활과학대학 식품영양학과',
            position: '교수',
            major: '식품 미생물학',
            office: '',
            email: '',
            home: '',
            tel: '02-880-2820',
            image: require('@/assets/images/profile/3/ji.png'),
          },
          {
            name: '최인철',
            depart: '사회과학대학 심리학과',
            position: '교수',
            major: '사회심리학',
            office: '',
            email: '',
            home: '',
            tel: '',
            image: require('@/assets/images/profile/3/choi.png'),
          },
        ],
      };
    },
    created() {},
    methods: {
      kFormatter,
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-blog.scss';
</style>
