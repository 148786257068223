<template>
  <b-card class="border-secondary">
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="p-0">
          <b-img-lazy
            :src="data.image"
            width="110"
            rounded
          />
        </div>
        <div class="pl-1">
          <h4 class="font-weight-bolder">{{ data.name }}</h4>
          <span class="card-text">
            <p class="mb-25">학과 : {{ data.depart }}</p>
            <p class="mb-25">직위 : {{ data.position }}</p>
            <p class="mb-25">전공 : {{ data.major }}</p>
            <p
              class="mb-25"
              v-if="data.office"
            >
              사무실 : {{ data.office }}
            </p>
          </span>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-around mt-1">
          <b-link
            :href="`tel:${data.tel}`"
            target="_self"
          >
            <b-img
              v-if="data.tel"
              v-bind="mainProps"
              rounded="circle"
              :src="require('@/assets/images/pages/profs/btn_tel.png')"
            />
          </b-link>
          <b-link
            :href="`mailto:${data.email}`"
            target="_self"
          >
            <b-img
              v-if="data.email"
              v-bind="mainProps"
              rounded="circle"
              :src="require('@/assets/images/pages/profs/btn_email.png')"
            />
          </b-link>
          <b-link
            :href="data.home"
            target="_blank"
          >
            <b-img
              v-if="data.home"
              v-bind="mainProps"
              rounded="circle"
              :src="require('@/assets/images/pages/profs/btn_home.png')"
            />
          </b-link>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCollapse,
    VBToggle,
    BImgLazy,
    BImg,
    BLink,
  } from 'bootstrap-vue';
  import { avatarText } from '@core/utils/filter';
  import Ripple from 'vue-ripple-directive';
  import { ref } from '@vue/composition-api';

  export default {
    components: {
      BCard,
      BButton,
      BRow,
      BCol,
      BAvatar,
      BCollapse,
      BImgLazy,
      BImg,
      BLink,
    },
    props: {
      // userData: {
      //   type: Object,
      //   required: true,
      // },
      data: {
        type: Object,
        required: true,
      },
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple,
    },
    setup() {
      const mainProps = { blank: false, width: 55, height: 55, class: 'mr-75' };
      return {
        mainProps,
      };
    },
  };
</script>

<style></style>
